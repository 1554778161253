/* Common Imports */

import { styled } from "@mui/system";
import React from "react";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import {
	setBusinessDashboardCurrentScreenThunk,
	setCreateNewPropertyCurrentPageThunk,
	setCreateNewPropertyTransactionTypeThunk,
	setCreateNewServiceCurrentPageThunk,
} from "../../redux-magic/store";

/* Component Imports */

import {
	BottomNavigation,
	BottomNavigationAction,
	Link,
	SvgIcon,
	SwipeableDrawer,
	Typography,
	useTheme,
} from "@mui/material";
import { useRouter } from "next/router";

/* Icon Imports */

import {
	AddCircle,
	ArrowCircleRight,
	ArrowCircleRightOutlined,
	BookmarkBorder,
	BookmarkBorderOutlined,
	Dashboard,
	DashboardOutlined,
	Home,
	HomeOutlined,
	OtherHouses,
	OtherHousesOutlined,
	PersonSearch,
	PersonSearchOutlined,
	Search,
	SearchOutlined,
	Settings,
	SettingsOutlined,
} from "@mui/icons-material";
import PostOFB from "../../public/images/icons/home/post_ofb.svg";
import PostProperty from "../../public/images/icons/home/post_property.svg";
import PostService from "../../public/images/icons/home/post_service.svg";

/* Modal Imports */

import CreateNewOFBWithUploadTool from "../modals/PropertyModal/CreateNewOFBWithUploadTool";
import CreatePropertyWithUploadTool from "../modals/PropertyModal/CreatePropertyWithUploadTool";
import CreateServiceWithUploadTool from "../modals/ServiceModals/CreateServiceWithUploadTool";
import { setBusinessDashboardCurrentScreen } from "../../redux-magic/reducers";

/* Styled Components */

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-around",
	alignItems: "center",
	height: "4rem",
	position: "fixed",
	bottom: 0,
	right: 0,
	left: 0,
	zIndex: 5,
	background: theme.palette.mode == "dark" ? "#424242" : "#FFFFFF",
	opacity: 1,
	borderRadius: "1rem 1rem 0rem 0rem",
	boxShadow:
		"rgba(0, 0, 0, 0.2) 0px 3px 3px 2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
	[theme.breakpoints.up("md")]: {
		display: "none",
	},
	"& .MuiBottomNavigationAction-label": {
		fontSize: "0.75rem",
		fontWeight: 400,
		lineHeight: "0.875rem",
	},
	"& .Mui-selected": {
		margin: "0rem 0rem 0rem 0rem",
		color: theme.palette.mode === "dark" ? "#FFAD69" : "#6e3037",
		"& .MuiBottomNavigationAction-label": {
			fontSize: "0.75rem",
			fontWeight: 500,
			lineHeight: "0.75rem",
			color: theme.palette.mode === "dark" ? "#FFAD69" : "#6e3037",
		},
	},
}));

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	"& .MuiDrawer-paper": {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		alignItems: "space-between",
		padding: "0.5rem 0.5rem 0.5rem 0.5rem",
		borderRadius: "8px 8px 0px 0px",
		gap: "0.5rem",
		width: "100%",
		background: theme.palette.background.default,
		maxHeight: "100%",
	},
}));

const Puller = styled("div")(({ theme }) => ({
	width: 30,
	height: 6,
	backgroundColor: "#E0E0E0",
	borderRadius: "8px",
	position: "absolute",
	left: "calc(50% - 15px)",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	padding: "0rem",
	gap: "0rem",
	overflow: "hidden",
}));

const NameTypeContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	alignItems: "flex-start",
	width: "100%",
	margin: "0rem 0rem 0rem 0.75rem",
}));

const ContentRow = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	width: "100%",
	padding: "0rem 0rem 0rem 0rem",
	gap: "0.75rem",
	cursor: "pointer",
	[theme.breakpoints.down("sm")]: {
		gap: "0rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
}));

const TypeTypo = styled(Typography)(({ theme }) => ({
	fontWeight: "400",
	fontSize: "0.875rem",
	color: theme.palette.mode == "dark" ? "#C6C6C6" : "#666666",
}));

const CustomLink = styled(Link)(({ theme }) => ({
	textDecoration: "none",
	fontSize: "1rem",
	fontWeight: 500,
	padding: "1rem 0.5rem 0.25rem 0.5rem",
	color: theme.palette.mode === "dark" ? "#FFFFFF" : "#000",
	"&:hover": {
		color: theme.palette.primary.main,
		cursor: "pointer",
	},
}));

const BottomNavDashboard = ({
	session,
	dispatch,
	profile_context,
	new_listing_url,
	newPropertyData,
	studio,
	newServiceData,
	dashboard_current_screen,
}: {
	session?: any;
	dispatch: Function;
	profile_context: StateInterface["profile_context"];
	new_listing_url: StateInterface["new_listing_url"];
	newPropertyData: StateInterface["new_property_data"];
	studio: StateInterface["is_studio"];
	newServiceData: StateInterface["new_service_data"];
	dashboard_current_screen: string;
}) => {
	const theme = useTheme();
	const router = useRouter();

	const is_dashboard: boolean = router.pathname.startsWith("/dashboard");

	/*

    	* Sign In Function

  	*/

	const signIn = () => {
		window.location.href =
			(process.env.PRODUCTION_URL || "") +
			"loading.html?callbackUrl=" +
			encodeURIComponent((process.env.PRODUCTION_URL || "") + "auth/login?callbackUrl=" + window.location.href);
	};

	/*

    	* Current page value for bottom navigation

  	*/

	const [currentPageValue, setCurrentPageValue] = React.useState<number>(0);

	/*

    	* Post drawer state

  	*/

	const [openPostMenu, setOpenPostMenu] = React.useState<boolean>(false);

	/*

		* Create property modal state and functions

	*/

	const [createPropertyOpen, setCreatePropertyOpen] = React.useState(false);

	const handleCreateOpen = () => {
		dispatch(setCreateNewPropertyCurrentPageThunk("navbar"));
		setCreatePropertyOpen(true);
		setOpenPostMenu(false);
	};

	const handleCreateClose = () => {
		setCreatePropertyOpen(false);
	};

	/*

		* Create service modal state and functions

	*/

	const [createServiceOpen, setCreateServiceOpen] = React.useState(false);

	const handleOpenNewService = () => {
		dispatch(setCreateNewServiceCurrentPageThunk("navbar"));
		setCreateServiceOpen(true);
		setOpenPostMenu(false);
	};

	const handleCloseNewService = () => {
		setCreateServiceOpen(false);
	};

	/*

		* Create OFB modal state and functions

	*/

	const [createOFBOpen, setCreateOFBOpen] = React.useState(false);

	const handleCreateNewOfbPropertyOpen = () => {
		dispatch(setCreateNewPropertyTransactionTypeThunk("ofb"));
		dispatch(setCreateNewPropertyCurrentPageThunk("navbar"));
		setCreateOFBOpen(true);
		setOpenPostMenu(false);
	};
	const handleCreateNewOfbPropertyClose = () => {
		setCreateOFBOpen(false);
	};

	return (
		<React.Fragment>
			{/* Create property modal */}

			<CreatePropertyWithUploadTool
				open={createPropertyOpen}
				handleClose={handleCreateClose}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newPropertyData={newPropertyData}
				studio={studio}
			/>

			{/* Create service modal */}

			<CreateServiceWithUploadTool
				open={createServiceOpen}
				handleClose={handleCloseNewService}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				session={session}
				newServiceData={newServiceData}
			/>

			{/* Create OFB modal */}

			<CreateNewOFBWithUploadTool
				open={createOFBOpen}
				handleClose={handleCreateNewOfbPropertyClose}
				creator_user_id={profile_context ? profile_context.user_id : ""}
				business_profile_id={profile_context ? profile_context.business_profile_id : ""}
				dispatch={dispatch}
				new_listing_url={new_listing_url ? new_listing_url : ""}
				profile_context={profile_context}
				session={session}
				newPropertyData={newPropertyData}
			/>

			{/* Post Drawer */}

			<CustomSwipeableDrawer
				anchor="bottom"
				open={openPostMenu}
				onClose={() => setOpenPostMenu(false)}
				onOpen={() => setOpenPostMenu(true)}
				swipeAreaWidth={156}
				disableSwipeToOpen={true}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					"& .MuiDrawer-paper": {
						background: theme.palette.background.paper,
						direction: router.locale === "ar-AE" ? "rtl" : "ltr",
					},
				}}
			>
				<Puller />

				<CustomLink
					href={`${process.env.PRODUCTION_URL}loading.html?callbackUrl=${encodeURIComponent(`/${router.locale}/intro`)}`}
					rel="noopener"
					target="_self"
					referrerPolicy="no-referrer"
					sx={{
						fontWeight: router.locale === "ar-AE" ? 600 : 500,
					}}
				>
					{router.locale === "ar-AE"
						? "ندخل في العقارات"
						: router.locale === "kn-IN"
							? "ರಿಯಲ್ ಎಸ್ಟೇಟ್‌ಗೆ ಪ್ರವೇಶಿಸಿ"
							: "Get into real estate"}
				</CustomLink>

				<ContentContainer
					sx={{
						padding: router.locale === "ar-AE" ? "0rem 0.5rem 0.75rem 0rem" : "0rem 0rem 0.75rem 0.5rem",
						gap: "1rem",
					}}
				>
					<ContentRow
						onClick={() => {
							if (session !== null) {
								handleCreateOpen();
							} else {
								router
									.push(
										{
											pathname: encodeURI("/"),
											query: { create_property: "true" },
										},
										"",
										{ locale: router.locale },
									)
									.then(() => {
										signIn();
									});
							}
						}}
					>
						<SvgIcon
							component={PostProperty}
							inheritViewBox={false}
							viewBox="0 0 37 40"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE"
									? "انشر عقارًا"
									: router.locale === "kn-IN"
										? "ಆಸ್ತಿಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
										: "Post property"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "بع أو أجر عقارك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಆಸ್ತಿಯನ್ನು ಮಾರಾಟ ಅಥವಾ ಬಾಡಿಗೆಗೆ ನೀಡಿ"
										: "Sell or rent out your property"}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null
								? handleOpenNewService()
								: router
										.push(
											{
												pathname: encodeURI("/"),
												query: { create_service: "true" },
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						<SvgIcon
							component={PostService}
							inheritViewBox={false}
							viewBox="0 0 36 42"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE"
									? "انشر خدمة"
									: router.locale === "kn-IN"
										? "ಸೇವೆಯನ್ನು ಪೋಸ್ಟ್ ಮಾಡಿ"
										: "Post service"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "ابحث عن عملاء لخدمتك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಸೇವೆಗಾಗಿ ಗ್ರಾಹಕರನ್ನು ಹುಡುಕಿ"
										: "Find clients for your service"}{" "}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>

					<ContentRow
						onClick={() => {
							session !== null
								? handleCreateNewOfbPropertyOpen()
								: router
										.push(
											{
												pathname: encodeURI("/"),
												query: { create_ofb: "true" },
											},
											"",
											{ locale: router.locale },
										)
										.then(() => {
											signIn();
										});
						}}
					>
						<SvgIcon
							component={PostOFB}
							inheritViewBox={false}
							viewBox="0 0 42 41"
							sx={{
								width: "2.5rem",
								height: "2.5rem",
							}}
						/>
						<NameTypeContainer>
							<Heading
								sx={{
									fontWeight: router.locale === "ar-AE" ? 600 : 500,
								}}
							>
								{router.locale === "ar-AE" ? "انشر أو إف بي" : router.locale === "kn-IN" ? "ಪೋಸ್ಟ್ OFB" : "Post OFB"}
							</Heading>
							<TypeTypo>
								{router.locale === "ar-AE"
									? "استفد من المواقع لصالحك"
									: router.locale === "kn-IN"
										? "ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕಾಗಿ ಸ್ಥಳಗಳನ್ನು ಬಳಸಿಕೊಳ್ಳಿ"
										: "Utilize locations to your benefit"}{" "}
							</TypeTypo>
						</NameTypeContainer>
					</ContentRow>
				</ContentContainer>
			</CustomSwipeableDrawer>

			{/* Bottom Navigation */}

			<CustomBottomNavigation
				showLabels
				value={
					is_dashboard && dashboard_current_screen === "home"
						? 0
						: is_dashboard && dashboard_current_screen === "leads"
							? 1
							: is_dashboard && dashboard_current_screen === "listings"
								? 3
								: is_dashboard && dashboard_current_screen === "settings"
									? 4
									: 0
				}
				sx={{
					direction: router.locale === "ar-AE" ? "rtl" : "ltr",
				}}
			>
				{/* Dashboard */}

				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "Dashboard" : router.locale === "kn-IN" ? "Dashboard" : "Dashboard"}
					disableRipple
					icon={
						is_dashboard && dashboard_current_screen === "home" ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<Dashboard
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
								}}
							>
								<DashboardOutlined fontSize="small" />
							</div>
						)
					}
					onClick={() => {
						if (is_dashboard) {
							dispatch(setBusinessDashboardCurrentScreenThunk("home"));
						} else {
							dispatch(setBusinessDashboardCurrentScreenThunk("home"));
							router.push("/dashboard?page=home");
						}
					}}
					// href={`${process.env.PRODUCTION_URL || ""}loading.html?callbackUrl=${encodeURIComponent(
					// 	`${process.env.PRODUCTION_URL || ""}`,
					// )}&locale=${router.locale || "en-IN"}`}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0rem 0rem 0rem 0rem",
						},
					}}
				/>

				{/* Leads */}

				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "Leads" : router.locale === "kn-IN" ? "Leads" : "Leads"}
					disableRipple
					icon={
						is_dashboard && dashboard_current_screen === "leads" ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<PersonSearch
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
								}}
							>
								<PersonSearchOutlined fontSize="small" />
							</div>
						)
					}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0rem 0rem 0rem 0rem",
						},
					}}
					onClick={() => {
						if (is_dashboard) {
							dispatch(setBusinessDashboardCurrentScreenThunk("leads"));
						} else {
							dispatch(setBusinessDashboardCurrentScreenThunk("leads"));
							router.push("/dashboard?page=leads");
						}
					}}
				/>

				{/* Post Menu */}

				<BottomNavigationAction
					icon={<AddCircle sx={{ fontSize: "3.5rem", color: "#1BA672" }} />}
					onClick={() => setOpenPostMenu(!openPostMenu)}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
						},
					}}
				/>

				{/* Listings */}

				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "Listings" : router.locale === "kn-IN" ? "Listings" : "Listings"}
					disableRipple
					icon={
						is_dashboard && dashboard_current_screen === "listings" ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<OtherHouses
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
								}}
							>
								<OtherHousesOutlined fontSize="small" />
							</div>
						)
					}
					onClick={() => {
						if (is_dashboard) {
							dispatch(setBusinessDashboardCurrentScreenThunk("listings"));
						} else {
							dispatch(setBusinessDashboardCurrentScreenThunk("listings"));
							router.push("/dashboard?page=listings");
						}
					}}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0rem 0rem 0rem 0rem",
						},
					}}
				/>

				{/* Settings */}

				<BottomNavigationAction
					label={router.locale === "ar-AE" ? "Settings" : router.locale === "kn-IN" ? "Settings" : "Settings"}
					disableRipple
					onClick={() => {
						if (is_dashboard) {
							dispatch(setBusinessDashboardCurrentScreenThunk("settings"));
						} else {
							dispatch(setBusinessDashboardCurrentScreenThunk("settings"));
							router.push("/dashboard?page=settings");
						}
					}}
					icon={
						is_dashboard && dashboard_current_screen === "settings" ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
									background: theme.palette.mode == "dark" ? "#663710" : "#ffe9d9",
									borderRadius: "1rem",
								}}
							>
								<Settings
									fontSize="small"
									sx={{ color: theme.palette.mode == "dark" ? "#FFAD69" : "#6e3037" }}
								/>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									width: "4rem",
									height: "2rem",
								}}
							>
								<SettingsOutlined fontSize="small" />
							</div>
						)
					}
					sx={{
						[theme.breakpoints.down("md")]: {
							minWidth: "0rem !important",
							margin: "0rem 0rem 0rem 0rem",
						},
					}}
				/>
			</CustomBottomNavigation>
		</React.Fragment>
	);
};

export default BottomNavDashboard;
